import React, { Component } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';
import config from '../../../data/SiteConfig';
import './BlogLogo.css';

class BlogLogo extends Component {
  render() {
    return (
      <Link className="blog-logo" to="/">
        <StaticImage src="../../../static/logos/logo-48.png" alt={config.siteTitle} />
      </Link>
    );
  }
}

export default BlogLogo;
